<template lang="pug">
li.dropdown.d-flex(:class='dropdownClasses')
  EcreativMoleculesButtonsDropDownToggle(
    :isActive="isActive",
    :itemName="itemName",
    @update:isActive="toggleDropdown"
  )
  transition(name='dropdownContent')
    .dropdown__menu.bg-white(
      v-if='isActive',
      role="menu",
      @click.stop
    )
      slot
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import EcreativMoleculesButtonsDropDownToggle from './Buttons/DropDownToggle.vue';

const { itemName, variation, columns } = defineProps({
  itemName: {
    default: '',
    type: String,
  },
  variation: {
    default: 'primary',
    type: String,
    validator: (value) => ['primary', 'nested'].includes(value),
  },
  columns: {
    type: String,
    default: '1',
  },
});

const isActive = ref(false);

const toggleDropdown = () => {
  isActive.value = !isActive.value;
};

const closeDropdown = () => {
  isActive.value = false;
};

const dropdownClasses = computed(() => [
  variation === 'nested' ? 'dropdown--nested' : 'dropdown--primary',
  getColumnClass.value,
]);

const getColumnClass = computed(() => {
  if (columns === '2') {
    return 'dropdown--2cols';
  }
  return 'dropdown--1col';
});
</script>

<style lang="scss" scoped>
$block: '.dropdown';

.dropdown__menu .navList .navItem .nav-link {
  padding: 10px 15px;
}

li.dropdown.d-flex.dropdown--nested > button {
  padding: 10px 15px;
}

#{$block} {
  &#{&} {
    position: static;
    @include media-breakpoint-up(md) {
      position: relative;
    }
    &:hover {
      .dropdown__arrow {
        color: $secondary;
      }
    }
  }

  #{$block}__arrow {
    color: $gray-300;
    transition:
      color 200ms ease-in,
      transform 200ms ease-in;
  }

  #{$block}__toggle {
    white-space: nowrap;
  }
  > #{$block}__menu {
    min-height: 20px;
  }
}

#{$block} {
  &#{$block} {
    &--2cols {
      > #{$block}__menu {
        @include media-breakpoint-up(md) {
          width: 38rem !important;
        }

        :deep(.navList) {
          @include media-breakpoint-up(md) {
            width: 50%;
            min-width: 200px;
            margin-bottom: 1.6em;

            &:last-of-type {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    &--1col {
      > #{$block}__menu {
        @include media-breakpoint-up(md) {
          width: 20rem !important;
        }
      }
    }
  }
}

#{$block} {
  &#{$block} {
    &--open {
      max-width: unset;
      white-space: nowrap;
      > #{$block}__toggle {
        > #{$block}__arrow {
          color: $secondary;
          transition: transform 250ms ease-out;
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
      > #{$block}__menu {
        #{$block} {
          #{$block}__toggle {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

#{$block} {
  &#{$block} {
    &--primary {
      > #{$block}__menu {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        border: 1px solid $gray-200;
        border-radius: 2px;
        z-index: 10;
        box-shadow:
          0 50px 100px -20px rgba(192, 192, 192, 0.25),
          0 30px 60px -30px rgba(192, 192, 192, 0.3),
          0 -18px 60px -10px rgba(192, 192, 192, 0.03);
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
          top: 60px;
        }
        @include media-breakpoint-up(md) {
          flex-direction: row;
          padding: 2rem;
          top: 2.4rem;
          right: 0;
          left: 0;
          transform: translate(-50%, 0);
          width: auto;
          min-width: 250px;
        }

        @include media-breakpoint-up(lg) {
          min-width: 300px;
        }
      }
      > #{$block}__menu {
        :deep(.navLink) {
          .navLink__links {
            .navItem--subLevelLink {
              padding: 0 0 clamp(0.4rem, 1vw, 0.5rem) 0;
            }
          }
        }
        :deep(.navItem) {
          &.navItem--mainLevelLink {
            font-weight: bold;
          }
          &.navItem--subLevelLink {
            padding: 1rem;
          }
        }
      }
    }
  }
}

#{$block} {
  &#{$block} {
    &--nested {
      position: relative;
      flex-direction: column;
      border-bottom: 1px solid $gray-200;
      #{$block}__menu {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        padding: 2rem 1rem 2rem 1.5rem;
        border: 1px solid $gray-200;
        &::before {
          content: '';
          position: absolute;
          top: 57px;
          left: 0;
          right: 0;
          height: 2rem;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
          );
          pointer-events: none;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3rem;
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
          );
          pointer-events: none;
        }
      }
      #{$block}__toggle {
        border-bottom: 0;
        padding: 1rem;
      }

      > #{$block}__menu {
        :deep(.navItem) {
          &.navItem--mainLevelLink {
            padding: 1rem 0 1rem 0;
            margin-top: 0.8rem;
            &:first-of-type {
              padding: 0 0 1rem 0;
              margin-top: 0rem;
            }
          }
          &.navItem--subLevelLink {
            padding: 0 0 1rem 0;
            border-bottom: 0;
            &:last-of-type {
              padding: 0 0 0 0;
            }
          }
        }
      }
    }
  }
}

.dropdownContent-enter-active,
.dropdownContent-leave-active {
  transition:
    opacity 0.25s ease-out,
    transform 0.25s ease-out;
}

.dropdownContent-leave-active {
  transition-duration: 0.2s;
}

.dropdownContent-enter,
.dropdownContent-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
