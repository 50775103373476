<template lang="pug">
p(:class="computedClasses")
  slot
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'none',
    validator: (value) =>
      ['1', '2', '3', '4', '5', '6', '7', 'none'].includes(value),
  },
  context: {
    type: String,
    default: 'none',
    validator: (value) =>
      [
        'none',
        'primary',
        'secondary',
        'dark',
        'light',
        'white',
        'danger',
        'success',
      ].includes(value),
  },
  fontWeight: {
    type: String,
    default: 'normal',
    validator: (value) =>
      ['normal', 'bold', 'light', 'semibold'].includes(value),
  },
});

const computedClasses = computed(() => {
  return [
    `fs-${props.size}`,
    `fw-${props.fontWeight}`,
    props.context !== 'none' ? `text-${props.context}` : '',
  ].filter(Boolean); // This removes any falsy value, ensuring no empty classes
});
</script>
