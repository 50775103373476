<template lang="pug">
.headerFixed(ref="fixedHeader" :class="{ 'isFixed': isFixed }")
  slot
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';
import { ref, onMounted, onUnmounted, nextTick } from 'vue';

const handleScroll = useDebounceFn(() => {
  isFixed.value = window.scrollY > headerOffsetTop.value;
}, 15);

const fixedHeader = ref(null);
const isFixed = ref(false);
const headerOffsetTop = ref(0); // Cache the offsetTop value

onMounted(() => {
  nextTick(() => {
    headerOffsetTop.value = fixedHeader.value.offsetTop;
    window.addEventListener('scroll', handleScroll);
  });
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.headerFixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: $white;
  transition: padding 0.25s ease-in;
  padding: clamp(1rem, 3cqi, 3rem) 0;
}

.isFixed {
  padding: clamp(0rem, 2cqi, 1rem) 0;
  transition: all 0.2s ease-out;

  :deep(.headerWrapper),
  :deep(.navWrapper),
  :deep(.headerLogo) {
    transition:
      width 0.25s ease-in,
      opacity 0.25s ease-in,
      top 0.25s ease-in,
      padding 0.25s ease-in;
  }

  :deep(.headerLogo) {
    width: clamp(3rem, 15cqi, 6rem);
    @include media-breakpoint-down(md) {
      top: -80px;
      opacity: 0;
    }
  }

  :deep(.navWrapper) {
    margin: clamp(0.4rem, 2cqi, 0.8rem) 0;
  }
}
</style>
