<template lang="pug">
li.navItem.nav-item
  EcreativAtomsLink.nav-link(
    :key="id"
    :class="[variant ? `link--${variant}` : '']"
    :fontWeight="fontWeight"
    :isUnderLine="isUnderLine"
    :link="link || undefined"
    :linkColor='linkColor'
    :scrollTo="link.scrollTo || undefined"
    :text="text"
    :linkType="linkType"
    @click="handleClick($event, link)"
  )
</template>

<script lang="ts" setup>
import { defineProps, withDefaults } from 'vue';
import { type NavItem, defaultNavItem } from 'typed-schemas';

import EcreativAtomsLink from '../atoms/Link.vue';
const modalStore = useModalStore();

function handleClick(event: MouseEvent, link: string): void {
  if (link === '#modalStore.openModal') {
    event.preventDefault();
    modalStore.openModal();
  }
}

withDefaults(defineProps<Partial<NavItem>>(), defaultNavItem);
</script>

<style scoped lang="scss">
.navItem {
  display: inline-flex;

  & :deep(.link--highlighted) {
    color: var(--ti-heading-color);
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    font-size: clamp(0.7rem, 2vw, 1rem);
    text-transform: uppercase;
  }

  & :deep(.link--underlined) {
    display: inline;
    color: var(--ti-heading-color);
    font-size: clamp(1.1rem, 2vw, 1.12rem);
    white-space: break-spaces;
    margin-bottom: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--ti-secondary);

    &:hover {
      margin-bottom: 0;
      border-bottom: 2px solid var(--ti-secondary);
    }
  }
}
</style>
